import './Services.css'

export const Services = () => (
    <div className='container-fluid px-0 d-sm-block d-md-block d-lg-flex d-xl-flex justify-content-center services-style pt-4 pb-4 vertical-service my-5 my-md-3 my-xl-0'>
        <div className='row justify-content-center align-items-center mx-0'>
            <div className='col-8 col-sm-7 col-md-10 col-lg-3 text-center services-font mx-4'>
                <span><i className="fa-solid fa-pen-ruler service-icon"></i></span>
                <p className='mb-0 service-title'><strong>Corte Personalizado</strong></p >
                <p>Ya sea que necesites piezas únicas o una producción en serie, nuestros cortes láser en acero cumplen con tus requisitos exactos.</p>
            </div>
            <div className='col-8 col-sm-7 col-md-10 col-lg-3 text-center services-font mx-4'>
                <span><i className="fa-solid fa-box service-icon"></i></span>
                <p className='mb-0 service-title'><strong>Puntualidad Garantizada</strong></p>
                <p>Nos comprometemos a cumplir rigurosamente con los plazos acordados para la entrega de tus productos, sin demoras.</p>
            </div>
            <div className='col-8 col-sm-7 col-md-10 col-lg-3 text-center services-font mx-4'>
                <span><i className="fa-solid fa-credit-card service-icon"></i></span>
                <p className='mb-0 service-title'><strong>Medios de pago</strong></p>
                <p>Contáctanos hoy para obtener más información sobre nuestras opciones de pago o para comenzar tu próximo proyecto de corte láser.</p>
            </div>
        </div>
    </div>
);