import "./UsSection.css";
import ussection from "../assets/us-section.jpg";
import ussection1 from "../assets/us-section1.jpg"
import ButtonPrincipal from "./ButtonPrincipal";

export const UsSection = () => (
  <>
    <div className="container-fluid px-0 img-us">
      <img src={ussection} alt="laser" className="col-12 img-h image-mobile" />
      <img src={ussection1} alt="laser" className="col-12 img-h image-full" />
      <div className="row">
        <div className="justify-content-center img-background col-10 col-sm-10 col-md-10 col-lg-4 col-xl-4">
          <h2 className=" us-sectionstyle">¡SOMOS FABRICANTES!</h2>
          <p className="mx-5 textus-justify">
            Somos una empresa joven y nos ocupamos de brindar soluciones de corte laser para estructuras metálicas llegando hasta la integración de productos terminados para las industrias de Energía, Mineras, Agro, Autopartes, Arquitectura, Ingeniería, Diseño y Publicidad.
            Nuestro objetivo es poder acompañarte profesionalmente en la optimización de la solución buscada consolando una ingeniería inversa para lograr la eficacia, en el menor tiempo posible.

          </p>
          <ButtonPrincipal className="col-12 mt-3" />
        </div>
      </div>
    </div>
  </>
);
