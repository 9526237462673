import './ButtonWs.css';
import iconwhatsapp from '../assets/icono-whatsapp.png';


export const ButtonWs = () => (
    <>
        <div class="floating-button">
            <a href="https://api.whatsapp.com/send?phone=541158069905&text=Hola!%20Quiero%20solicitar%20un%20presupuesto%20de..." target='blank' class="btn-ws">
                <img src={iconwhatsapp} alt='icono-logo whatsapp' />
            </a>
        </div>
    </>
);