import './App.css';

import { NavBar } from './components/NavBar';
import { PrincipalSection } from './components/PrincipalSection';
import { Products } from './components/Products';
import { Services } from './components/Services';
import { UsSection } from './components/UsSection';
import { Contacts } from './components/Contacts';
import { Footer } from './components/FooterHigher';
import { ButtonWs } from './components/ButtonWs';

function App() {
  return (
    <>
    <ButtonWs />
    <NavBar />
    <PrincipalSection />
    <Services />
    <UsSection />
    <Products />
    <Contacts />
    <Footer />
    </>
  );
}

export default App;
