import './IconContact.css'

export const IconContacts = () => (
    <>
        <div className="d-md-block d-lg-flex d-xl-flex justify-content-center mt-5 mb-5 mb-md-1 mb-lg-1 mb-xl-1 icon-margin">
            <div className="col-10 col-sm-10 col-md-10 col-xl-3 col-lg-3 icon-style mx-0 mx-lg-2">
                <i class="fa-brands fa-square-instagram d-flex justify-content-lg-center justify-content-xl-center icon-size"><a href='https://www.instagram.com/laserfer.ok/' className='icon-font mt-2 mb-3 mx-2 icon-link' target='blank'>@Laserfer.ok</a></i>
            </div>
            <div className="col-10 col-sm-10 col-md-10 col-xl-3 col-lg-3 icon-style mx-0 mx-lg-2">
                <i class="fa-solid fa-map-pin d-flex justify-content-lg-center justify-content-xl-center icon-size"><a href='https://maps.app.goo.gl/BKR8hrHzxFMnQ4HFA' className='icon-font mt-2 mb-3 mx-2 icon-link'>Guaminí 7199,
                    Virrey del pino.
                    Bs As.</a></i>
            </div>
            <div className="col-10 col-sm-10 col-md-10 col-xl-3 col-lg-3 icon-style mx-0 mx-lg-2">
                <i class="fa-solid fa-envelope d-flex justify-content-lg-center justify-content-xl-center icon-size"><p className='icon-font mt-2 mx-2'>Info@laserfer.com</p></i>
            </div>
        </div>
    </>
)