import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Swal from 'sweetalert2'

const root = ReactDOM.createRoot(document.getElementById('root'));

const urlParams = new URLSearchParams(window.location.search);
  const isEmailSend = urlParams.get('email');
  if (isEmailSend) {
    Swal.fire({
      text: 'Nos estaremos comunicando pronto',
      icon: 'success',
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#32b4b8c2'
    })
  } else {

  }
  
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

