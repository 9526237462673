import './Footer.css'

export const Footer = () => {
    return (
        <>
        {/* <div className='container'>
            <p className='text-footer mt-4 text-center'>Diseñado y desarrollado por Higher Web ©</p>
        </div> */}
        </>
    )
    }