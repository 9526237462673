import Button from 'react-bootstrap/Button';
import "./ButtonPrincipal.css";

function ButtonContact() {
  return (
    <>
      <div className="mb-md-5 mb-lg-5 mg-xl-5 mt-5 pt-3">
        <Button className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 btn-principal btn-activebg" type="submit">
          CONTACTO
        </Button>
      </div>
    </>
  );
}

export default ButtonContact;