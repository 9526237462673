import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NavBar.css'
import React, { useRef } from 'react';
import logoImage from '../assets/logo.png'


export const NavBar = () => {
    const collapseRef = useRef(null);

    const navigate = (nav) => {
        let elementId = "";
        switch (nav) {
            case 1:
                elementId = "home";
                break;
            case 2:
                elementId = "onclickservicios";
                break;
            case 3:
                elementId = "contacto";
                break;
            default:
                break;
        }

        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

        if (collapseRef.current && collapseRef.current.classList.contains('show')) {
            collapseRef.current.classList.remove('show');
        }
    };

    return (
        <Navbar expand="lg" className="nav fixed-top">
            <Container className='padding-resp'>
                <Navbar.Brand href="#home" className='nav-logo'>
                    <img src={logoImage} alt='icono-logo whatsapp' className='size-logo' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="nav-space" ref={collapseRef}>
                    <Nav>
                        <Nav.Link className='nav-links mx-4 my-2' translate='no' onClick={() => navigate(1)}>Home</Nav.Link>
                        <Nav.Link className='nav-links mx-4 my-2' onClick={() => navigate(2)}>Servicios</Nav.Link>
                        <Nav.Link className='nav-links mx-4 my-2' onClick={() => navigate(3)}>Contacto</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
