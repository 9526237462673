import React from "react";
import { TabTitle } from "../components/Title";
import producto1 from '../assets/product1.jpg';
import producto2 from '../assets/product2.jpg';
import producto3 from '../assets/product3.jpg';
import producto4 from '../assets/product4.jpg';
import './Product.css'

export const Products = () => {
    return (
        <>
            <div className="container" id="productos">
                <TabTitle />
                <div className="d-sm-block d-md-block d-lg-flex d-xl-3 text-center my-md-5 my-lg-5 my-xl-5 pb-5 justify-content-center">
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 my-sm-4 my-md-4 my-4">
                        <img src={producto1} alt='producto 1' className="product-img" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 my-sm-4 my-md-4 my-4">
                        <img src={producto2} alt='producto 2' className="product-img" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 my-sm-4 my-md-4 mt-4">
                        <img src={producto3} alt='producto 3' className="product-img" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 my-sm-4 my-md-4 mt-4">
                        <img src={producto4} alt='producto 4' className="product-img" />
                    </div>
                </div>
            </div>
        </>
    );
};