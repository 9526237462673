import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import ButtonContact from './ButtonContact';
import Swal from 'sweetalert2';
import { IconContacts } from './IconContacts';
import { Higherweb } from './Higherweb';
import './Contact.css';

export const Contacts = () => {
    const captcha = useRef(null);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: ''
    });

    const [formErrors, setFormErrors] = useState({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: ''
    });

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...formErrors };

        if (formData.nombre.trim() === '') {
            newErrors.nombre = 'Por favor ingresa tu Nombre';
            valid = false;
        } else {
            newErrors.nombre = '';
        }

        if (formData.email.trim() === '') {
            newErrors.email = 'Por favor ingresa tu Email';
            valid = false;
        } else {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(formData.email)) {
                newErrors.email = 'Por favor ingresa un Email válido';
                valid = false;
            } else {
                newErrors.email = '';
            }
        }

        if (formData.telefono.trim() === '') {
            newErrors.telefono = 'Por favor ingresa tu Teléfono';
            valid = false;
        } else {
            newErrors.telefono = '';
        }

        if (formData.mensaje.trim() === '') {
            newErrors.mensaje = 'Por favor ingresa tu Mensaje';
            valid = false;
        } else {
            newErrors.mensaje = '';
        }

        setFormErrors(newErrors);
        return valid;
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const submit = (e) => {
        e.preventDefault();

        if (validateForm() && captchaVerified) {
            console.log('Enviando formulario:', e.target.action);
            e.target.submit();

            Swal.fire({
                icon: 'success',
                text: '¡Mensaje enviado con éxito!',
                confirmButtonColor: '#32b4b8c2'
            });
        } else {
            let errorMessage = "Completa correctamente todos los campos.";
            if (!captchaVerified) {
                errorMessage = "Acepta el captcha para enviar el formulario.";
            }
            Swal.fire({
                text: errorMessage,
                confirmButtonColor: '#32b4b8c2'
            });
        }
    };

    return (
        <>
            <div className='container-fluid contact-bg mt-5 text-center' id='contacto' name='contacto'>
                <h1 className='contact-title py-5' >DEJANOS TU MENSAJE</h1>
                <div className='row justify-content-center'>
                    <Form className='col-10 col-sm-10 col-md-10 col-lg-5 col-xl-5 pb-5' method="POST" action="contacto.php" onSubmit={submit}>
                        <Form.Group className="mt-3 form-control " controlId="exampleForm.ControlInput1">
                            <Form.Control className='form-style' type="text" placeholder="Nombre" name='nombre' value={formData.nombre} onChange={onChange} />
                        </Form.Group>
                        {formErrors.nombre && <p className="error-message">{formErrors.nombre}</p>}

                        <Form.Group className="mt-3 form-control " controlId="exampleForm.ControlInput1">
                            <Form.Control className='form-style' type="email" placeholder="Email" name='email' value={formData.email} onChange={onChange} />
                        </Form.Group>
                        {formErrors.email && <p className="error-message">{formErrors.email}</p>}

                        <Form.Group className="mt-3 form-control " controlId="exampleForm.ControlInput1">
                            <Form.Control className='form-style' type="text" placeholder="Telefono" name='telefono' value={formData.telefono} onChange={onChange} />
                        </Form.Group>
                        {formErrors.telefono && <p className="error-message">{formErrors.telefono}</p>}

                        <Form.Group className="mt-3 form-control p-0 bb-0" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={4} placeholder="Mensaje" className='px-4' name='mensaje' value={formData.mensaje} onChange={onChange} />
                            {formErrors.mensaje && <p className="error-message">{formErrors.mensaje}</p>}
                        </Form.Group>

                        <ReCAPTCHA
                            className='mt-3'
                            ref={captcha}
                            sitekey="6LfMn88pAAAAAKShqPfgbcuuYx1hRfpubkz-yz4-"
                            onChange={() => setCaptchaVerified(true)}
                        />
                        <ButtonContact />
                    </Form>

                    <IconContacts />
                    <Higherweb />
                </div>
            </div>
        </>
    )
}
