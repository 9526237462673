import React from 'react';
import Button from 'react-bootstrap/Button';
import "./ButtonPrincipal.css";

function navigate(nav) {
  const element = document.getElementById("contacto");
  element.scrollIntoView({ behavior: 'smooth' });
}

function ButtonPrincipal({ className }) {
  return (
    <div className={`mb-2 ${className}`} id="onclickservicios">
      <Button className="col-10 btn-principal" onClick={() => navigate()} >
        CONTACTO
      </Button>
    </div>
  );
}

export default ButtonPrincipal;
