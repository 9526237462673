import './PrincipalSection.css'
import portada from '../assets/portada.jpg'
import portada1 from '../assets/portada1.jpg'

export const PrincipalSection = () => (
    <>
        <div className='container-fluid px-0' id='home'>
            <img src={portada} alt='portada' className='principal-section col-12 principalimg-h image-mobile' />
            <img src={portada1} alt='portada' className='principal-section col-12 principalimg-h image-full' />
            <div className="principal-text d-flex justify-content-center align-items-start align-items-md-center align-items-lg-center flex-column width-mobile">
                <h1 className='principal-textfont h1-ps'> GRABADO & CORTE LASER | A medida  </h1>
                <h2 className='secondary-textfont text-start text-md-center text-lg-center h2-ps '> Ofrecemos servicios de primera categoría a terceros </h2>
            </div>
        </div>
    </>
);